<!--
 * @Author: John
 * @Date: 2022-02-22 11:45:19
 * @LastEditTime: 2022-05-07 20:00:36
 * @LastEditors: John
 * @Description: 修改密码
 * @FilePath: \jjms_ui\src\views\func\resetPwd.vue
 * @Copyright John
-->
<template>
    <div>
        <my-breadcrumb :data="breadcrumbData" />
        <el-form :model="formData" :rules="formRule" ref="updatePwdForm" label-width="200px">
            <el-form-item label="请输入旧密码：" prop="oldPwd">
                    <el-input v-model="formData.oldPwd" suffix-icon="el-icon-lock" clearable show-password></el-input>
            </el-form-item>
            <el-form-item label="请输入新密码：" prop="newPwd">
                <el-input v-model="formData.newPwd" suffix-icon="el-icon-lock" clearable show-password></el-input>
            </el-form-item>
            <el-form-item label="请再次输入新密码：" prop="repeatNewPwd">
                <el-input v-model="formData.repeatNewPwd" suffix-icon="el-icon-lock" clearable show-password></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="submit">更新</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
import myBreadcrumb from '@/components/my-breadcrumb.vue'
import api from '@/api/req_api.js'
import sha256 from 'crypto-js/sha256'

export default {
    name: 'remind',  // 重置密码
    components: {
        myBreadcrumb
    },
    data() {
        let checkOldPwd = (rule, value, callback) => {
            let secretPwd = sha256(value).toString();
            if(secretPwd != this.userInfo.password) {
                callback(new Error('旧密码不正确!'));
                return;
            }
            callback();
        };
        let validateRePassword = (rule, value, callback) => {
            if(value !== this.formData.newPwd) {
                callback(new Error('两次输入密码不一致!'));
                return;
            }
            callback();
        };
        return {
            breadcrumbData: [
                {title: '功能设置'},
                {title: '重置密码', url: '/main/func-reset-pwd'}
            ],
            formData: {
                oldPwd: '',
                newPwd: '',
                repeatNewPwd: ''
            },
            formRule: {
                oldPwd: [
                    {required: true, message: '请输入旧密码!', trigger: 'blur'},
                    { min: 6, max: 10, message: '长度在 6 到 10 个字符之间', trigger: 'blur'},
                    {validator: checkOldPwd, trigger: 'blur'}
                ],
                newPwd: [
                    {required: true, message: '请输入新密码!', trigger: 'blur'},
                    { min: 6, max: 10, message: '长度在 6 到 10 个字符之间', trigger: 'blur'},
                ],
                repeatNewPwd: [
                    {required: true, message: '请再次输入新密码!', trigger: 'blur'},
                    { min: 6, max: 10, message: '长度在 6 到 10 个字符之间', trigger: 'blur'},
                    {validator: validateRePassword, trigger: 'blur'}
                ]
            },
            userInfo: null
        }
    },
    methods: {
        loadData() {
            if(this.$store.state.user) {   // 如果本地缓存的用户数据存在使用本地缓存数据
                this.userInfo = this.$store.state.user;  
                return;
            }
            api.getUserInfo()   // 请求当前用户数据
                .then(data => {
                    if(data != null) {
                        this.userInfo = data; 
                    }
                })
        },
        submit() {
            this.$refs['updatePwdForm'].validate(valid => {
                if(valid) {
                    this.formData.repeatNewPwd = '111111111111';
                    this.formData.oldPwd = sha256(this.formData.oldPwd).toString();
                    this.formData.newPwd = sha256(this.formData.newPwd).toString();
                    api.modifyPwd(this.formData)
                        .then(data => {
                            if(data != null) {
                                this.userInfo.password = this.formData.newPwd;
                                this.$store.commit('modify', this.userInfo);
                                this.$message.success('密码修改成功，请用新密码重新登录！');
                                
                                // 退出登录
                                api.logout()
                                    .then(data => {
                                        console.log(data);
                                    if(data !== 200) {
                                        this.$store.commit('logout')
                                        this.$router.push({ path: '/login' })
                                    }
                                })
                            }
                        })
                }
            });
        }
    },
    mounted() {
        this.loadData();
    }
}
</script>
